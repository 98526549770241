.footer {
  margin-top: auto;
  background-color: $color-black;
  color: #fff;
  &__wrapper {
    @include fullwidth-wrapper(xy);
    flex-direction: column;
    text-align: center;
    @include on(medium-and-up) {
      flex-direction: row;
    }
    justify-content: flex-start;
    padding: $spacing-global/2 $spacing-global;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    &__item, li {
      display: inline-block;

      margin: {
        top: $spacing-global/4;
        bottom: $spacing-global/4;
      }
      width: 100%;
      @include on(medium-and-up) {
        margin-right: $spacing-global/2;
        width: auto;
      }
    }
    &--social {
      @include on(medium-and-up) {
        margin-left: auto;
      }
    }
    &--meta {}
  }
  &__list--social &__list__item {
    margin: $spacing-global/4 0.25em;
    width: auto;
    @include on(medium-and-up) {
      margin-right: 0;
      margin-left: 0.5em;
    }
  }
  &__link, a {
    display: block;
    color: #fff;
    text-decoration: none;
    &:hover {
      color: $color-primary;
    }
    &--social {
      padding: 0.5em;
      border-radius: 100%;
      background-color: #fff;
      &:hover {
        background-color: $color-primary;
      }
      svg {
        width: 1em;
        height: 1em;
        color: $color-black;
        display: block;
      }
    }
    &--contact {
      svg {
        display: inline-block;
        margin-right: 0.25em;
        width: 1em;
        height: 1em;
        vertical-align: middle;
      }
    }
  }
}
