.scroll-indicator {
  display: flex;
  justify-content: center;
  height: 0px;
  &__icon {
    display: block;
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    background-color: $color-primary;
    position: relative;
    z-index: 99;
    transform: translateY(-50%) scale(1);
    transition: transform .2s;
    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }
}