.hero {
  color: #fff;
  background: {
    color: $color-background-gray;
    size: cover;
    position: center;
  }

  overflow: hidden;

  @include color-overlay();

  &__wrapper {
    position: relative;
    z-index: 9;
    width: 100%;
    @include fullwidth-wrapper($flex: false);

    @include on(medium-and-up) {
      @include fullwidth-wrapper(xy);
      justify-content: space-between;
      //flex-flow: wrap;
      //flex-direction: column;
      min-height: 23rem;
      height: 1rem;
    }
    //height: 1rem;
    padding: $spacing-global;
  }

  &__title {
    text-shadow: $text-shadow;
    width: 100%;
    @include on(medium-and-up) {
      font-size: 3rem;
    }
    margin: 0;
  }
  &--center &__title {
    @include on(medium-and-up) {
      max-width: 85%;
    }
    text-align: center;
    margin: 0 auto;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%;
    p {
      max-width: 100%;
    }
  }

  .person {
    @include on(large-and-up) {
      margin: 0 0 0 $spacing-global*2;
    }
    flex-grow: 0;
  }
}
