.area {
  margin: $spacing-global 0;
  &__wrapper {
    @include fullwidth-wrapper($flex: false);
    @include on(medium-and-up) {
      @include fullwidth-wrapper();
      padding: $spacing-global;
    }
    flex-flow: wrap;
    z-index: 1;
    position: relative;
  }
  &__title {
    margin-top: 0;
  }
  &__components {
    @include on(medium-and-up) {
      margin: 0 -$spacing-global/2;
      display: flex;
      flex-basis: 100%;
      flex-shrink: 0;
      flex-grow: 1;
      flex-flow: wrap;
    }
  }
  &__container {
    display: block;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }
  &__link {
    @include on(medium-and-up) {
      margin-top: $spacing-global*2;
    }
    margin-left: auto;
  }
  &--dark {
    background-color: $color-dark;
    color: #fff;
    margin: 0;
    padding: $spacing-global 0;
  }
  &--people {
    background: {
      size: cover;
      position: center;
    }
  }
  &:last-child {
    //padding: 0 0 $spacing-global 0;
    //margin: $spacing-global 0 0 0;
    //background-color: $color-gray-light;
  }
}
