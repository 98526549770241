$color-primary: #95288B;
$color-dark: #2e1c2a;
$color-black: #090909;
$color-gray: #686868;
$color-gray-med: #d8d8d8;
$color-gray-light: #f8f8f8;
$color-background-gray: $color-gray-med;

$color-warning: #B33A3A;
$color-success: #4BB543;

$text-shadow: 0 0 6px rgba(0,0,0,0.5);
$box-shadow: 0 2px 10px rgba(0,0,0,0.45);
$box-shadow-minor: 0 2px 10px rgba(0,0,0,0.05);
$box-shadow-off: 0 0 10px rgba(0,0,0,0);

svg  {
    circle,
    path {
        fill: currentColor;
    }
}
