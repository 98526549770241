.pagination {
  display: flex;
  margin: 0 auto;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  &__list {
    list-style: none;
    margin: $spacing-global auto;
    padding: 0;
    display: flex;
    //justify-content: space-between;
    &__item {
      //margin: 0 $spacing-global/4;
      display: block;
      &--active {
        background-color: $color-primary;
        color: #fff;
        border-radius: 2rem;
      }
      &--active &__link {
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
      &__link {
        text-decoration: none;
        padding: $spacing-global/4;
        //display: block;
        display: flex;
        height: 2rem;
        min-width: 2rem;
        text-align: center;
        align-items: center;
        justify-content: center;
        &--disabled {
          color: $color-gray-med;
        }
      }
    }
  }
}
