@mixin card-hover($disabled: false) {
  box-shadow: $box-shadow-off;
  transition: transform .2s ease-in-out, box-shadow .2s  ease-in-out;
  transform: scale(1) perspective(1px) translateZ(0);
  backface-visibility: hidden;
  @if($disabled == true) {
    &:hover {
      box-shadow: $box-shadow-off;
      transform: scale(1) perspective(1px) translateZ(0);
    }
  } @else {
    &:hover {
      box-shadow: $box-shadow;
      transform: scale(1.0325) perspective(1px) translateZ(0);
    }
  }
}
