.images {
  margin: $spacing-global 0;
  &__wrapper {
    @include fullwidth-wrapper($flex: false, $padded: false);
    @include on(medium-and-up) {
      padding: 0 $spacing-global;
    }
  }
  &__container {
    display: flex;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    @include on(medium-and-up) {
      margin: 0 -$spacing-global/2;
    }
  }
  &__link,
  &__figure {
    width: 100%;
    margin: 0;
    @include on(medium-and-up) {
      margin: $spacing-global/2;
    }
    //@include card-hover();
  }
  &__link > &__figure {
    margin: 0;
  }
  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
}
