.header {
  background-color: #fff;
  //height: 6rem;
  //z-index: 2;
  //position: sticky;
  //top: 0;
  box-shadow: $box-shadow-minor;
  &__wrapper {
    //@include fullwidth-wrapper($flex: false);
    display: flex;
    flex-flow: wrap;
    @include on(large-and-up) {
      @include fullwidth-wrapper(y);
      flex-flow: nowrap;
    }
    position: relative;
  }

  &__logo {
    display: block;
    height: 3rem;
    width: 6rem;
    padding: 0.5rem 0;
    box-sizing: content-box;
    color: $color-primary;
    flex-shrink: 0;
    @include image-replace("../images/Innovalor-logo.svg"); // SVG contains gradients, changed those manually after AI. export to be without transform and regular vector coords
    background-size: contain;
    background-origin: content-box;
    margin: 0 0 0 $spacing-global;
    @include on(medium-and-up) {
      height: 4rem;
    }
    @include on(large-and-up) {
      height: 5rem;
      margin: 0;
    }

  }

}
