$font-brand: "CorpidOfficeC1";
@import "./font-brand";

$font-family-headings: #{$font-brand}, sans-serif;
$font-family-body: sans-serif;
$font-size-base: 16px;
$line-height: 1.35;

// Headings font is same as body, no need to set
//h1, h2, h3, h4, h5, h6,
//.h1, .h2, .h3, .h4, .h5, .h6 {
//  font-family: $font-brand;
//}

/*
 * Base heading styles based on Blink styles
 * @link source https://chromium.googlesource.com/chromium/blink/+/master/Source/core/css/html.css
 */

// Normally the :matches() selector in blink matches all these elements
// But without :matches() that gets way too verbose.
// We'lll only use our use case
// $matches-containers: (article, aside, nav, section);
 $matches-containers: (article, section);
// This mixin mimics the matches selector but is a lot more verbose
// Use with limited $matches or
@mixin matches($matches) {
  @each $match in $matches {
    #{$match} & {
      @content;
    }
  }
}

// Use this to return element and .element
// Usage:
// #{classify(h1)} {
//  color: red;
//  }
@function classify($element) {
  @return "#{$element}, .#{$element}";
}

h1 {
  @include matches( $matches-containers) {
    @extend h2;
    @include matches( $matches-containers) {
      @extend h3;
      // We're not using these and going any deeper will result in A LOT of CSS.
      //@include matches( $matches-containers) {
      //  @extend h4;
      //  @include matches( $matches-containers) {
      //    @extend h5;
      //    @include matches( $matches-containers) {
      //      @extend h6;
      //    }
      //  }
      //}
    }
  }
}

#{classify(h1)} {
  font-size: 2em;
  font-weight: bold;
  margin: {
    top: 0.67em;
    bottom: 0.67em;
    left: 0;
    right: 0;
  }
}

#{classify(h2)} {
  font-size: 1.5em;
  font-weight: bold;
  margin: {
    top: 0.83em;
    bottom: 0.83em;
  }
}

#{classify(h3)} {
  font-size: 1.17em;
  font-weight: bold;
  margin: {
    top: 1em;
    bottom: 1em;
  }
}

#{classify(h4)} {
  font-weight: bold;
  margin: {
    top: 1.33em;
    bottom: 1.33em;
  }
}

#{classify(h5)} {
  font-size: .83em;
  font-weight: bold;
  margin: {
    top: 1.67em;
    bottom: 1.67em;
  }
}

#{classify(h6)} {
  font-size: .67em;
  font-weight: bold;
  margin: {
    top: 2.33em;
    bottom: 2.33em;
  }
}


