.video-component {
  background-color: $color-gray-light;
  margin: $spacing-global 0;
  &__wrapper {
  }

  &__container {
  }

  &__play-area {
    @include fullwidth-wrapper(x, $flex: false, $padded: false);
    @include on(medium-and-up) {
      @include fullwidth-wrapper(x, $flex: false, $padded: true);
    }
      &__wrapper {
      background-color: $color-gray-med;
      @include on(medium-and-up) {
        padding: $spacing-global 0;
      }

    }
    &__container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      width: 100%;
      overflow: hidden;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__playlist {
    max-width: $max-width;
    margin: 0 auto;
    padding: $spacing-global/2 $spacing-global;

    &__list {
      display: flex;
      list-style: none;
      margin: 0 -#{$spacing-global/4};
      padding: 0;
      justify-content: center;
      flex-direction: column;
      @include on(medium-and-up) {
        flex-direction: row;
      }

      &__item {
        margin: $spacing-global/4;
        background: #fff;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        @include on(medium-and-up) {
          display: block;
          max-width: 25%;
        }
        @include card-hover();
        &__thumbnail {
          height: 0;
          background-size: cover;
          width: 50%;
          padding-bottom: 20%;
          @include on(medium-and-up) {
            padding-bottom: 50%;
            width: 100%;
          }
          display: block;
          position: relative;
          @include color-overlay($color: rgba($color-dark, 0.2), $pseudo: before);
          @include color-overlay($opacity: 0);
          svg {
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            transform:translateX(-50%) translateY(-50%);
            transform-origin: center;
            z-index: 9;
            transition: all .2s;
            height: 10vw;
            width: 10vw;
            @include on(medium-and-up) {
              height: 4rem;
              width: 4rem;
            }
          }
        }
        &:hover &__thumbnail svg {
        }
        &--active &__thumbnail {
          @include color-overlay($color: rgba($color-dark, 0.2), $pseudo: before, $opacity: 0);
          @include color-overlay($opacity: 1);
        }
        &__label {
          padding: $spacing-global-block;
          display: block;
          @extend h3;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }

}
