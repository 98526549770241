@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-up-fade-out {
  0% {
    opacity: 1;
    max-height: 100vh;
  }
  50% {
    opacity: 0;
    max-height: 100vh;
  }
  100% {
    opacity: 0;
    max-height: 0px;
    padding: {
      top: 0;
      bottom: 0;
    }
  }
}
