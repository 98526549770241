.button {
  color: #fff;
  cursor: pointer;
  display: table;//inline-block;
  padding: $spacing-global-block;
  border-radius: 10rem;
  border: 1px solid $color-primary;
  text-decoration: none;
  background-color: $color-primary;
  font-family: $font-family-headings;
  font-size: 1em;

  &:hover {
    background-color: #fff;
    color: $color-primary;
  }

  &:after {
    content: ">";
    display: inline-block;
    transform: scaleX(0.5);
    margin-left: 0.5rem;
  }
  &--filled {
    // Default
  }
  &--text-only {
    color: $color-primary;
    background-color: transparent;
    padding: 0;
    border: none;
    &:hover {
      color: $color-dark;
    }
  }
  &--inline {
    display: inline-table;
  }
  &--outline {
    background-color: transparent;
    border-color: #fff;
    &:hover {
      background-color: #fff;
      color: $color-primary;
      border-color: #fff;
    }
  }
  &--dark {
    border-color: #fff;
    background-color: $color-dark;
    &:hover {
      color: $color-dark;
    }
  }
}
