@font-face {
  font-family: $font-brand;
  src: url("../fonts/Corpid_C1_500_Regular/CorpidC1_500_Regular.eot");
  src: url("../fonts/Corpid_C1_500_Regular/CorpidC1_500_Regular.eot?#iefix") format("embedded-opentype"),
  url("../fonts/Corpid_C1_500_Regular/CorpidC1_500_Regular.woff") format("woff"),
  url("../fonts/Corpid_C1_500_Regular/CorpidC1_500_Regular.svg#CorpidC1_500_Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: $font-brand;
  src: url("../fonts/Corpid_C1_500i_RegularItalic/CorpidC1_500i_RegularItalic.eot");
  src: url("../fonts/Corpid_C1_500i_RegularItalic/CorpidC1_500i_RegularItalic.eot?#iefix") format("embedded-opentype"),
  url("../fonts/Corpid_C1_500i_RegularItalic/CorpidC1_500i_RegularItalic.woff") format("woff"),
  url("../fonts/Corpid_C1_500i_RegularItalic/CorpidC1_500i_RegularItalic.svg#Corpid_C1_500i_RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: $font-brand;
  src: url("../fonts/Corpid_C1_700_Bold/CorpidC1_700_Bold.eot");
  src: url("../fonts/Corpid_C1_700_Bold/CorpidC1_700_Bold.eot?#iefix") format("embedded-opentype"),
  url("../fonts/Corpid_C1_700_Bold/CorpidC1_700_Bold.woff") format("woff"),
  url("../fonts/Corpid_C1_700_Bold/CorpidC1_700_Bold.svg#Corpid_C1_700_Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: $font-brand;
  src: url("../fonts/Corpid_C1_700i_BoldItalic/CorpidC1_700i_BoldItalic.eot");
  src: url("../fonts/Corpid_C1_700i_BoldItalic/CorpidC1_700i_BoldItalic.eot?#iefix") format("embedded-opentype"),
  url("../fonts/Corpid_C1_700i_BoldItalic/CorpidC1_700i_BoldItalic.woff") format("woff"),
  url("../fonts/Corpid_C1_700i_BoldItalic/CorpidC1_700i_BoldItalic.svg#Corpid_C1_700i_BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}