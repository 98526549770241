$bp-small: 640px;
$bp-medium: 960px;

$mq-map: (
    small: "(max-width: #{$bp-small - 1px})",
    medium-and-up:  "(min-width: #{$bp-small})",
    large-and-up: "(min-width: #{$bp-medium})"
);

@mixin on($variation: portrait) {
  $mq: map-get($mq-map, $variation);
  @media #{$mq} {
    @content;
  }
}
