@mixin color-overlay($color: rgba($color-primary, 0.8), $pseudo: after, $opacity: 1) {
  position: relative;
  &:#{$pseudo} {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: $opacity;
    transition: all .2s;
    //background-color: $color;
    background-image: linear-gradient(45deg, $color, adjust_hue($color, -45));
    //background-image: linear-gradient(-33deg, $color, rgba($color, 0));
  }
}
