$max-width: 60rem;
$spacing-global: 2rem;
$spacing-global-block-v: 0.75rem;
$spacing-global-block-h: 1.25rem;
$spacing-global-block: $spacing-global-block-v $spacing-global-block-h;

@mixin flex-width($width) {
  flex: {
    grow: 0;
    shrink: 1;
    basis: $width;
  }
  max-width: $width;
}
