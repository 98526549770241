.entrances {
  color: #fff;
  position: relative;
  margin: 0 auto;
  max-width: $max-width;
  padding: {
    left: $spacing-global;
    right: $spacing-global;
    bottom: $spacing-global;
  }
  @include on(medium-and-up) {
    padding-top: $spacing-global;
  }
  z-index: 2;
  //@include on(large-and-up) {
  //  display: flex;
  //  flex-flow: wrap;
  //}
  &__wrapper {
    //margin: 0 #{-$spacing-global};
    @include on(medium-and-up) {
      display: flex;
    }
    width: 100%;
  }
  &__entrance {
    margin: 0 #{-$spacing-global/2};
    @include on(medium-and-up) {
      display: flex;
      width: 100%;
      align-items: center;
      margin: 0;
      border-left: 1px solid #fff;
      &--first {
        border-left: none;
      }
    }
    &__link {
      color: #fff;
      text-decoration: none;
      @include on(medium-and-up) {
        padding: $spacing-global/4 $spacing-global/2;
        margin: 0 $spacing-global/2;
      }
      width: 100%;
      display: block;


      //@include color-overlay($color: rgba(#fff,0.5), $opacity: 0);
      //@include card-hover();

      &:hover {
        //color: $color-primary;
        color: #fff;
        //@include color-overlay($color: rgba(#fff,0.5), $opacity: 1);

      }
    }
    &__button {
      margin: $spacing-global/2 auto;
    }
    &__title {
      text-shadow: $text-shadow;
      margin: 0;
    }
    &__content {
      z-index: 3;
      position: relative;
      width: 100%;
      text-align: center;
    }

  }
}
