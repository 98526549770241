.text {
  margin: $spacing-global 0;
  &__wrapper {
    @include fullwidth-wrapper($flex: false);
  }
  &__container {
    @include on(medium-and-up) {
      max-width: 85%;
    }
    margin: 0 auto;
  }
}
