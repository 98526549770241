.service-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  $service-spacing: $spacing-global/2;
  &__item {
    display: block;
    color: $color-primary;
    background-color: $color-gray-light;
    width: 100%;
    margin: $service-spacing/2 0;
    @include on(medium-and-up) {
      margin: $service-spacing;
      flex-basis: calc(100% / 3 - #{$service-spacing * 2});
    }

    @include card-hover();

    &__wrapper {
      text-decoration: none;
      display: block;
      color: inherit;
      padding: $spacing-global/2 0 0 0;
      //height: 0px;
      //padding-bottom: #{100%/3*2};
      //position: relative;
    }

    &__icon {
      overflow: hidden;
      margin: $spacing-global/2 0;
      svg {
        width:100%;
        height: 6rem;
      }
    }
    &__label {
      text-align: center;
      margin: $spacing-global/2 0;
    }
  }
}
