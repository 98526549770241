svg circle,
svg path {
  fill: currentColor; }

@font-face {
  font-family: "CorpidOfficeC1";
  src: url("../fonts/Corpid_C1_500_Regular/CorpidC1_500_Regular.eot");
  src: url("../fonts/Corpid_C1_500_Regular/CorpidC1_500_Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Corpid_C1_500_Regular/CorpidC1_500_Regular.woff") format("woff"), url("../fonts/Corpid_C1_500_Regular/CorpidC1_500_Regular.svg#CorpidC1_500_Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "CorpidOfficeC1";
  src: url("../fonts/Corpid_C1_500i_RegularItalic/CorpidC1_500i_RegularItalic.eot");
  src: url("../fonts/Corpid_C1_500i_RegularItalic/CorpidC1_500i_RegularItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Corpid_C1_500i_RegularItalic/CorpidC1_500i_RegularItalic.woff") format("woff"), url("../fonts/Corpid_C1_500i_RegularItalic/CorpidC1_500i_RegularItalic.svg#Corpid_C1_500i_RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "CorpidOfficeC1";
  src: url("../fonts/Corpid_C1_700_Bold/CorpidC1_700_Bold.eot");
  src: url("../fonts/Corpid_C1_700_Bold/CorpidC1_700_Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Corpid_C1_700_Bold/CorpidC1_700_Bold.woff") format("woff"), url("../fonts/Corpid_C1_700_Bold/CorpidC1_700_Bold.svg#Corpid_C1_700_Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "CorpidOfficeC1";
  src: url("../fonts/Corpid_C1_700i_BoldItalic/CorpidC1_700i_BoldItalic.eot");
  src: url("../fonts/Corpid_C1_700i_BoldItalic/CorpidC1_700i_BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Corpid_C1_700i_BoldItalic/CorpidC1_700i_BoldItalic.woff") format("woff"), url("../fonts/Corpid_C1_700i_BoldItalic/CorpidC1_700i_BoldItalic.svg#Corpid_C1_700i_BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic; }

/*
 * Base heading styles based on Blink styles
 * @link source https://chromium.googlesource.com/chromium/blink/+/master/Source/core/css/html.css
 */
h1, .h1 {
  font-size: 2em;
  font-weight: bold;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0; }

h2, article h1, section h1, .h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 0.83em;
  margin-bottom: 0.83em; }

h3, article article h1, section article h1, article section h1, section section h1, .video-component__playlist__list__item__label, .h3 {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em; }

h4, .h4 {
  font-weight: bold;
  margin-top: 1.33em;
  margin-bottom: 1.33em; }

h5, .h5 {
  font-size: .83em;
  font-weight: bold;
  margin-top: 1.67em;
  margin-bottom: 1.67em; }

h6, .h6 {
  font-size: .67em;
  font-weight: bold;
  margin-top: 2.33em;
  margin-bottom: 2.33em; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes slide-up-fade-out {
  0% {
    opacity: 1;
    max-height: 100vh; }
  50% {
    opacity: 0;
    max-height: 100vh; }
  100% {
    opacity: 0;
    max-height: 0px;
    padding-top: 0;
    padding-bottom: 0; } }

*, *:before, *:after {
  box-sizing: border-box; }

html {
  font-size: 16px;
  font-family: CorpidOfficeC1, sans-serif;
  line-height: 1.35; }
  @media (min-width: 1400px) {
    html {
      font-size: 18px; } }

body {
  background-color: #fff;
  margin: 0;
  color: #090909; }

/** Angular specific styles **/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.link-as-wrapper {
  text-decoration: inherit;
  color: inherit; }

a {
  color: #2e1c2a;
  text-decoration: underline;
  cursor: pointer; }
  a:hover {
    color: #95288B; }

input, button {
  font-family: inherit;
  font-size: inherit; }

.mgnlEditorBar,
.mgnlPlaceholder {
  flex-basis: 100%; }

.header {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); }
  .header__wrapper {
    display: flex;
    flex-flow: wrap;
    position: relative; }
    @media (min-width: 960px) {
      .header__wrapper {
        display: flex;
        align-items: center;
        max-width: 60rem;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 2rem;
        flex-flow: nowrap; } }
  .header__logo {
    display: block;
    height: 3rem;
    width: 6rem;
    padding: 0.5rem 0;
    box-sizing: content-box;
    color: #95288B;
    flex-shrink: 0;
    background: url("../images/Innovalor-logo.svg") center no-repeat;
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    background-size: contain;
    background-origin: content-box;
    margin: 0 0 0 2rem; }
    @media (min-width: 640px) {
      .header__logo {
        height: 4rem; } }
    @media (min-width: 960px) {
      .header__logo {
        height: 5rem;
        margin: 0; } }

.sticky {
  left: 0;
  right: 0;
  top: 0;
  z-index: 12345678;
  transition: all .4s ease-in-out;
  transform: translateY(0%);
  opacity: 1;
  position: absolute; }
  .sticky--enabled {
    position: fixed; }
  .sticky--enabled.sticky--hidden {
    transform: translateY(-100%);
    opacity: 0; }
  .sticky--enabled + .sticky__placeholder {
    width: 100%;
    display: block; }

.navigation {
  width: 100%;
  flex-basis: 100%;
  max-height: 0px;
  overflow: hidden;
  transition: all .3s;
  display: flex;
  flex-direction: column; }
  .navigation--open {
    max-height: 40rem; }
  @media (min-width: 960px) {
    .navigation {
      display: block;
      max-height: none;
      margin-left: 1rem;
      flex-direction: row; } }
  .navigation__list {
    list-style: none;
    padding: 0;
    margin: 0; }
    @media (min-width: 960px) {
      .navigation__list {
        display: flex; } }
    .navigation__list__item {
      position: relative; }
      @media (min-width: 960px) {
        .navigation__list__item {
          margin-left: 1rem;
          position: static; } }
      .navigation__list__item--search {
        margin-left: auto; }
      .navigation__list__item__link {
        text-decoration: none;
        color: #090909;
        padding: 1rem 0;
        display: block;
        position: relative;
        font-weight: bold;
        padding: 1rem 2rem;
        border-bottom: 1px solid #d8d8d8; }
        .navigation__list__item__link:hover {
          color: #95288B; }
        .navigation__list__item__link--active {
          color: #95288B; }
        @media (min-width: 960px) {
          .navigation__list__item__link {
            padding: 1rem 0;
            border-bottom: none; } }
        .navigation__list__item__link__toggle {
          display: flex;
          margin-left: auto;
          padding: 0rem 1.5rem;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          justify-content: center;
          align-items: center; }
          @media (min-width: 960px) {
            .navigation__list__item__link__toggle {
              display: none; } }
          .navigation__list__item__link__toggle:after {
            content: ">";
            display: inline-block;
            transition: all .2s;
            transform: rotate(90deg) scaleX(0.5); }
        .navigation__list__item__link--search {
          display: flex;
          align-items: center; }
          .navigation__list__item__link--search svg {
            order: 9999;
            margin-left: auto;
            height: 1.5em;
            display: inline-block; }
          @media (min-width: 960px) {
            .navigation__list__item__link--search span {
              display: none; }
            .navigation__list__item__link--search svg {
              order: initial; } }
  .navigation__list--depth-2 {
    max-height: 0px;
    overflow: hidden;
    transition: all .2s;
    z-index: 99;
    background: #f8f8f8;
    flex-direction: column; }
    @media (min-width: 960px) {
      .navigation__list--depth-2 {
        display: none;
        position: absolute;
        min-width: 240px;
        max-width: 320px;
        max-height: none; } }
    @media (min-width: 960px) {
      .navigation__list--depth-2 {
        background: white;
        margin: 0 -1rem; } }
  .navigation__list__item:hover .navigation__list--depth-2 {
    display: flex; }
  .navigation__list__item--open .navigation__list--depth-2 {
    max-height: 20rem; }
  .navigation__list__item--open .navigation__list__item__link__toggle:after {
    transform: rotate(-90deg) scaleX(0.5); }
  .navigation__list--depth-2 .navigation__list__item {
    margin: 0; }
    .navigation__list--depth-2 .navigation__list__item__link {
      border-bottom: 1px solid #d8d8d8; }
      @media (min-width: 960px) {
        .navigation__list--depth-2 .navigation__list__item__link {
          background-color: #fff;
          padding: 1rem; } }

.nav-icon {
  display: flex;
  cursor: pointer;
  overflow: visible;
  width: 1.5rem;
  height: 1.5rem;
  flex-flow: wrap;
  margin: auto 2rem auto auto; }
  @media (min-width: 960px) {
    .nav-icon {
      display: none; } }
  .nav-icon__bar {
    transition: all .2s;
    height: 3px;
    width: 1.5rem;
    background-color: currentColor;
    margin: auto;
    flex-basis: 100%;
    transform-origin: 50% 50%;
    border-radius: 3px; }

.nav-icon--open .nav-icon__bar:first-child {
  transform: rotate(45deg) translate(0.375rem, 0.375rem); }

.nav-icon--open .nav-icon__bar:nth-child(2) {
  transform: scaleX(1.25);
  opacity: 0; }

.nav-icon--open .nav-icon__bar:last-child {
  transform: rotate(-45deg) translate(0.375rem, -0.375rem) translateX(-1px); }

.language {
  order: -1;
  padding: 1rem 2rem; }
  @media (min-width: 960px) {
    .language {
      padding: 0;
      position: absolute;
      right: 2rem;
      top: -1px; } }
  .language__list {
    display: flex;
    list-style: none;
    margin: 0 -1px;
    padding: 0; }
    @media (min-width: 960px) {
      .language__list {
        margin: 0; } }
    .language__list__item {
      display: block;
      flex: 1;
      width: 100%; }
      @media (min-width: 960px) {
        .language__list__item {
          flex: none;
          width: auto;
          display: inline-table; } }
      .language__list__item__link {
        text-decoration: none;
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
        display: block;
        border-top: 1px solid #95288B;
        border-right: 1px solid #95288B;
        border-bottom: 1px solid #95288B;
        border-left: 1px solid #95288B;
        color: #95288B;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0);
        transition: transform .2s ease-in-out, box-shadow .2s  ease-in-out;
        transform: scale(1) perspective(1px) translateZ(0);
        backface-visibility: hidden;
        background-color: #fff;
        padding: 0.2rem 0.5rem; }
        .language__list__item__link:hover {
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.45);
          transform: scale(1.0325) perspective(1px) translateZ(0); }
        .language__list__item__link--active {
          background-color: #95288B;
          color: #fff; }
          .language__list__item__link--active:hover {
            color: #fff; }
        .language__list__item__link:before {
          color: inherit;
          content: attr(lang);
          display: block;
          font-family: CorpidOfficeC1, sans-serif;
          font-size: 1rem;
          font-weight: bold;
          line-height: 1.35;
          text-transform: uppercase; }

.hero {
  color: #fff;
  background-color: #d8d8d8;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative; }
  .hero:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    transition: all .2s;
    background-image: linear-gradient(45deg, rgba(149, 40, 139, 0.8), rgba(77, 40, 149, 0.8)); }
  .hero__wrapper {
    position: relative;
    z-index: 9;
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    padding: 2rem; }
    @media (min-width: 640px) {
      .hero__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 60rem;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 2rem;
        justify-content: space-between;
        min-height: 23rem;
        height: 1rem; } }
  .hero__title {
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    width: 100%;
    margin: 0; }
    @media (min-width: 640px) {
      .hero__title {
        font-size: 3rem; } }
  .hero--center .hero__title {
    text-align: center;
    margin: 0 auto; }
    @media (min-width: 640px) {
      .hero--center .hero__title {
        max-width: 85%; } }
  .hero__container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%; }
    .hero__container p {
      max-width: 100%; }
  .hero .person {
    flex-grow: 0; }
    @media (min-width: 960px) {
      .hero .person {
        margin: 0 0 0 4rem; } }

.metadata {
  background-color: #f8f8f8; }
  .metadata__wrapper {
    display: flex;
    max-width: 60rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 2rem; }
  .metadata__date {
    display: block;
    padding: 0.75rem 1.25rem;
    font-size: 0.8rem;
    margin-left: auto;
    margin-right: -1.25rem; }
    .metadata__date__published {
      color: #95288B; }
    .metadata__date__modified {
      color: #95288B; }

.area {
  margin: 2rem 0; }
  .area__wrapper {
    max-width: 60rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    flex-flow: wrap;
    z-index: 1;
    position: relative; }
    @media (min-width: 640px) {
      .area__wrapper {
        display: flex;
        max-width: 60rem;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 2rem;
        padding: 2rem; } }
  .area__title {
    margin-top: 0; }
  @media (min-width: 640px) {
    .area__components {
      margin: -1rem;
      display: flex;
      flex-basis: 100%;
      flex-shrink: 0;
      flex-grow: 1;
      flex-flow: wrap; } }
  .area__container {
    display: block;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1; }
  .area__link {
    margin-left: auto; }
    @media (min-width: 640px) {
      .area__link {
        margin-top: 4rem; } }
  .area--dark {
    background-color: #2e1c2a;
    color: #fff;
    margin: 0;
    padding: 2rem 0; }
  .area--people {
    background-size: cover;
    background-position: center; }

.entrances {
  color: #fff;
  position: relative;
  margin: 0 auto;
  max-width: 60rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  z-index: 2; }
  @media (min-width: 640px) {
    .entrances {
      padding-top: 2rem; } }
  .entrances__wrapper {
    width: 100%; }
    @media (min-width: 640px) {
      .entrances__wrapper {
        display: flex; } }
  .entrances__entrance {
    margin: 0 -1rem; }
    @media (min-width: 640px) {
      .entrances__entrance {
        display: flex;
        width: 100%;
        align-items: center;
        margin: 0;
        border-left: 1px solid #fff; }
        .entrances__entrance--first {
          border-left: none; } }
    .entrances__entrance__link {
      color: #fff;
      text-decoration: none;
      width: 100%;
      display: block; }
      @media (min-width: 640px) {
        .entrances__entrance__link {
          padding: 0.5rem 1rem;
          margin: 0 1rem; } }
      .entrances__entrance__link:hover {
        color: #fff; }
    .entrances__entrance__button {
      margin: 1rem auto; }
    .entrances__entrance__title {
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
      margin: 0; }
    .entrances__entrance__content {
      z-index: 3;
      position: relative;
      width: 100%;
      text-align: center; }

.quote {
  color: #fff;
  position: relative;
  background-size: cover;
  background-position: center; }
  .quote:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    transition: all .2s;
    background-image: linear-gradient(45deg, rgba(149, 40, 139, 0.8), rgba(77, 40, 149, 0.8)); }
  .quote__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 60rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    position: relative;
    z-index: 2;
    flex-flow: wrap;
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media (min-width: 640px) {
      .quote__wrapper {
        padding-top: 10rem;
        padding-bottom: 4rem; } }
  .quote__block {
    margin: 0 auto; }
    @media (min-width: 640px) {
      .quote__block {
        width: 85%; } }
  .quote__link {
    float: right; }
  .quote__text {
    font-size: 2rem;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    margin: 0; }
    @media (min-width: 640px) {
      .quote__text {
        font-size: 3rem; } }
    .quote__text--with-quotation:before {
      content: "\201C"; }
    .quote__text--with-quotation:after {
      content: "\201D"; }
  .quote__attribution:before {
    content: "\2014"; }
  .quote__footer {
    margin-top: 2rem;
    flex-basis: 100%; }
    @media (min-width: 640px) {
      .quote__footer {
        margin-top: 4rem; } }

.block {
  position: relative;
  flex: 1;
  width: 100%;
  margin: 2rem 0; }
  @media (min-width: 640px) {
    .block {
      margin: 1rem;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: calc(100% / 3 - 2rem);
      max-width: calc(100% / 3 - 2rem); } }
  @media (min-width: 640px) {
    .block--larger {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: calc(100% / 2 - 2rem);
      max-width: calc(100% / 2 - 2rem); } }
  .block__header--hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
    transition: transform .2s ease-in-out, box-shadow .2s  ease-in-out;
    transform: scale(1) perspective(1px) translateZ(0);
    backface-visibility: hidden; }
    .block__header--hover:hover {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.45);
      transform: scale(1.0325) perspective(1px) translateZ(0); }
  .block__image {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0.5rem; }
    .block__image img {
      width: 100%;
      height: auto;
      display: block; }
  .block__author {
    color: #95288B;
    display: block;
    margin: 0.5rem 0; }
  .block__date {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 0.75rem 1.25rem;
    color: #95288B; }
  .block__title {
    margin: 0; }
  .block__text {
    margin: 0;
    color: #686868; }

.person {
  background-color: #fff;
  display: flex;
  position: relative;
  margin: 1rem 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  transition: transform .2s ease-in-out, box-shadow .2s  ease-in-out;
  transform: scale(1) perspective(1px) translateZ(0);
  backface-visibility: hidden; }
  @media (min-width: 640px) {
    .person {
      margin: 1rem;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: calc(100% / 4 - 2rem);
      max-width: calc(100% / 4 - 2rem); } }
  .person:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.45);
    transform: scale(1.0325) perspective(1px) translateZ(0); }
  .person__wrapper, .person__link {
    display: block;
    text-decoration: none;
    width: 100%; }
  @media (max-width: 639px) {
    .person__wrapper {
      display: flex; } }
  .person__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block; }
  .person__detail {
    background-color: white;
    color: #090909;
    padding: 0.75rem;
    text-align: center;
    display: block;
    width: 100%; }
    @media (min-width: 640px) {
      .person__detail {
        margin-top: auto; } }
    .person__detail__name {
      margin: 0; }
    .person__detail__role {
      display: block;
      font-weight: bold; }
    .person__detail__mail {
      display: block;
      margin-top: 0.75rem;
      margin-bottom: 0.375rem;
      font-size: 0.65rem;
      text-decoration: none;
      color: inherit; }
      .person__detail__mail svg {
        display: inline-block;
        margin-right: 0.25em;
        width: 1em;
        height: 1em;
        vertical-align: middle; }
  .person--alone {
    flex: none; }
    @media (max-width: 639px) {
      .person--alone {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0);
        transition: transform .2s ease-in-out, box-shadow .2s  ease-in-out;
        transform: scale(1) perspective(1px) translateZ(0);
        backface-visibility: hidden;
        background-color: transparent; }
        .person--alone:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0);
          transform: scale(1) perspective(1px) translateZ(0); } }
  .person--larger {
    flex: 1;
    width: auto;
    max-width: calc(100%/3 - 2rem); }
  .person--alone .person__image {
    border-radius: 100%;
    margin: 1rem 2rem;
    max-width: calc(100% - 4rem);
    max-height: calc(100% - 1rem);
    width: auto; }
    @media (min-width: 640px) {
      .person--alone .person__image {
        margin: 1rem 2rem 0 2rem; } }
  @media (max-width: 639px) {
    .person--alone .person__wrapper > .person__link {
      display: none; } }
  @media (max-width: 639px) {
    .person--alone .person__detail {
      text-align: left;
      background-color: transparent;
      padding: 0; }
      .person--alone .person__detail > a {
        color: #fff; } }
  .person-with-text {
    flex: 1; }
    .person-with-text .person {
      max-width: none; }
    @media (min-width: 640px) {
      .person-with-text {
        max-width: calc(100% / 3); }
        .person-with-text .person__text {
          margin: 1rem; } }

.text {
  margin: 2rem 0; }
  .text__wrapper {
    max-width: 60rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 2rem; }
  .text__container {
    margin: 0 auto; }
    @media (min-width: 640px) {
      .text__container {
        max-width: 85%; } }

.images {
  margin: 2rem 0; }
  .images__wrapper {
    max-width: 60rem;
    margin: 0 auto;
    width: 100%;
    height: 100%; }
    @media (min-width: 640px) {
      .images__wrapper {
        padding: 0 2rem; } }
  .images__container {
    display: flex;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1; }
    @media (min-width: 640px) {
      .images__container {
        margin: -1rem; } }
  .images__link, .images__figure {
    width: 100%;
    margin: 0; }
    @media (min-width: 640px) {
      .images__link, .images__figure {
        margin: 1rem; } }
  .images__link > .images__figure {
    margin: 0; }
  .images__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block; }

.text-image {
  margin: 2rem 0; }
  .text-image__wrapper {
    max-width: 60rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 2rem; }
  .text-image__container {
    display: flex;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    flex-flow: wrap;
    margin: 0 auto; }
    @media (min-width: 640px) {
      .text-image__container {
        max-width: 85%; } }
  .text-image__text {
    flex: 2; }
    @media (max-width: 639px) {
      .text-image__text {
        flex-basis: 100%; } }
    .text-image__text h2, .text-image__text article h1, article .text-image__text h1, .text-image__text section h1, section .text-image__text h1 {
      margin-top: 0; }
    .text-image__text > p:first-child {
      margin-top: 0; }
  .text-image__title {
    flex-basis: 100%;
    margin-top: 0; }
    @media (max-width: 639px) {
      .text-image__title {
        order: -3; } }
  .text-image__link {
    flex-basis: 100%; }
  .text-image__figure {
    width: 100%;
    margin: 0;
    flex: 2; }
    @media (max-width: 639px) {
      .text-image__figure {
        flex-basis: 100%;
        margin: 1rem -2rem;
        order: -1; } }
    @media (min-width: 960px) {
      .text-image__figure {
        flex: 1; } }
    @media (min-width: 640px) {
      .text-image__figure--right {
        margin-left: 2rem; }
      .text-image__figure--left {
        margin-right: 2rem;
        margin-right: 2rem; } }
  .text-image__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block; }
    .text-image__image--padded {
      padding: 2rem; }

.video-component {
  background-color: #f8f8f8;
  margin: 2rem 0; }
  .video-component__play-area {
    max-width: 60rem;
    margin: 0 auto;
    width: 100%;
    height: 100%; }
    @media (min-width: 640px) {
      .video-component__play-area {
        max-width: 60rem;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 2rem; } }
    .video-component__play-area__wrapper {
      background-color: #d8d8d8; }
      @media (min-width: 640px) {
        .video-component__play-area__wrapper {
          padding: 2rem 0; } }
    .video-component__play-area__container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      width: 100%;
      overflow: hidden; }
      .video-component__play-area__container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  .video-component__playlist {
    max-width: 60rem;
    margin: 0 auto;
    padding: 1rem 2rem; }
    .video-component__playlist__list {
      display: flex;
      list-style: none;
      margin: 0 -0.5rem;
      padding: 0;
      justify-content: center;
      flex-direction: column; }
      @media (min-width: 640px) {
        .video-component__playlist__list {
          flex-direction: row; } }
      .video-component__playlist__list__item {
        margin: 0.5rem;
        background: #fff;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0);
        transition: transform .2s ease-in-out, box-shadow .2s  ease-in-out;
        transform: scale(1) perspective(1px) translateZ(0);
        backface-visibility: hidden; }
        @media (min-width: 640px) {
          .video-component__playlist__list__item {
            display: block;
            max-width: 25%; } }
        .video-component__playlist__list__item:hover {
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.45);
          transform: scale(1.0325) perspective(1px) translateZ(0); }
        .video-component__playlist__list__item__thumbnail {
          height: 0;
          background-size: cover;
          width: 50%;
          padding-bottom: 20%;
          display: block;
          position: relative;
          position: relative;
          position: relative; }
          @media (min-width: 640px) {
            .video-component__playlist__list__item__thumbnail {
              padding-bottom: 50%;
              width: 100%; } }
          .video-component__playlist__list__item__thumbnail:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 1;
            transition: all .2s;
            background-image: linear-gradient(45deg, rgba(46, 28, 42, 0.2), rgba(37, 28, 46, 0.2)); }
          .video-component__playlist__list__item__thumbnail:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            transition: all .2s;
            background-image: linear-gradient(45deg, rgba(149, 40, 139, 0.8), rgba(77, 40, 149, 0.8)); }
          .video-component__playlist__list__item__thumbnail svg {
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            transform-origin: center;
            z-index: 9;
            transition: all .2s;
            height: 10vw;
            width: 10vw; }
            @media (min-width: 640px) {
              .video-component__playlist__list__item__thumbnail svg {
                height: 4rem;
                width: 4rem; } }
        .video-component__playlist__list__item--active .video-component__playlist__list__item__thumbnail {
          position: relative;
          position: relative; }
          .video-component__playlist__list__item--active .video-component__playlist__list__item__thumbnail:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            transition: all .2s;
            background-image: linear-gradient(45deg, rgba(46, 28, 42, 0.2), rgba(37, 28, 46, 0.2)); }
          .video-component__playlist__list__item--active .video-component__playlist__list__item__thumbnail:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 1;
            transition: all .2s;
            background-image: linear-gradient(45deg, rgba(149, 40, 139, 0.8), rgba(77, 40, 149, 0.8)); }
        .video-component__playlist__list__item__label {
          padding: 0.75rem 1.25rem;
          display: block;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%; }

.service-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: wrap;
  width: 100%; }
  .service-list__item {
    display: block;
    color: #95288B;
    background-color: #f8f8f8;
    width: 100%;
    margin: 0.5rem 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
    transition: transform .2s ease-in-out, box-shadow .2s  ease-in-out;
    transform: scale(1) perspective(1px) translateZ(0);
    backface-visibility: hidden; }
    @media (min-width: 640px) {
      .service-list__item {
        margin: 1rem;
        flex-basis: calc(100% / 3 - 2rem); } }
    .service-list__item:hover {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.45);
      transform: scale(1.0325) perspective(1px) translateZ(0); }
    .service-list__item__wrapper {
      text-decoration: none;
      display: block;
      color: inherit;
      padding: 1rem 0 0 0; }
    .service-list__item__icon {
      overflow: hidden;
      margin: 1rem 0; }
      .service-list__item__icon svg {
        width: 100%;
        height: 6rem; }
    .service-list__item__label {
      text-align: center;
      margin: 1rem 0; }

.downloads-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .downloads-list__item {
    border-bottom: 1px solid #d8d8d8;
    margin: 0 0 0.5rem 0; }
    .downloads-list__item__link {
      display: block;
      text-decoration: none;
      width: 100%;
      padding: 0.5rem 0; }
      .downloads-list__item__link svg {
        margin: 0 0.5rem;
        display: inline-block;
        vertical-align: text-bottom; }

.scroll-indicator {
  display: flex;
  justify-content: center;
  height: 0px; }
  .scroll-indicator__icon {
    display: block;
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    background-color: #95288B;
    position: relative;
    z-index: 99;
    transform: translateY(-50%) scale(1);
    transition: transform .2s; }
    .scroll-indicator__icon:hover {
      transform: translateY(-50%) scale(1.1); }

.background-parallax {
  position: relative;
  overflow: hidden; }
  .background-parallax__image {
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }

.form {
  width: 100%; }
  .form.ng-enter {
    animation: fade-in .3s; }
  .form.ng-leave {
    animation: slide-up-fade-out .3s; }
  .form--inline {
    width: auto;
    display: flex;
    align-items: flex-end;
    flex-flow: wrap;
    margin: 0 -0.5rem; }
    .form--inline.gatedDownload {
      flex-direction: column;
      align-items: flex-start; }
    .form--inline .form__group {
      display: inline-block;
      margin: 0 0.5rem;
      flex-grow: 1; }
      .form--inline .form__group.gatedDownload {
        width: 80%; }
      .form--inline .form__group--stay {
        flex-grow: 0; }
    .form--inline .form__messages {
      margin: 0 0.5rem; }
  .form__group {
    margin: 1rem 0;
    display: block; }
  .form__messages {
    display: block;
    width: 100%; }
  .form__label {
    cursor: pointer;
    font-weight: bold;
    display: block;
    margin: 0.5rem 0;
    position: relative; }
    .form__label--light {
      font-weight: normal; }
  .form__field {
    -webkit-appearance: none;
    padding: 0.75rem 0.625rem;
    border: 1px solid #d8d8d8;
    display: block;
    margin: 0.5rem 0;
    width: 100%;
    transition: all .2s;
    border-radius: 0.2rem; }
    .form__field--textarea {
      min-height: 14rem;
      resize: vertical; }
  .form__checkbox {
    position: absolute;
    visibility: hidden; }
    .form__checkbox__dummy {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      border-radius: 0.2rem;
      border: 1px solid #d8d8d8;
      position: relative;
      vertical-align: -2px;
      margin-right: 0.5rem;
      transition: all .1s; }
      .form__checkbox__dummy:before, .form__checkbox__dummy:after {
        content: "";
        display: block;
        transform: scaleX(1) rotate(36deg);
        position: absolute;
        transition: all .1s;
        opacity: 0; }
      .form__checkbox__dummy:before {
        height: 1.3em;
        width: 0.8em;
        top: -0.55em;
        left: 0.1em;
        border-right: 0.3em solid #95288B;
        border-bottom: 0.3em solid #95288B;
        z-index: 2; }
      .form__checkbox__dummy:after {
        border-right: 0.5em solid #fff;
        border-bottom: 0.5em solid #fff;
        top: -0.75em;
        left: 0.06em;
        width: 1em;
        height: 1.6em; }
    .form__checkbox:checked + .form__checkbox__dummy {
      border-color: #95288B; }
      .form__checkbox:checked + .form__checkbox__dummy:before, .form__checkbox:checked + .form__checkbox__dummy:after {
        opacity: 1; }
  .form__button {
    margin: 0.5rem 0;
    white-space: nowrap;
    display: block; }
  .form__feedback {
    border: 1px solid #d8d8d8;
    padding: 0.75rem 1.25rem;
    background-color: #f8f8f8;
    margin: 1rem 0;
    transition: all .2s;
    border-radius: 0.2rem; }
    .form__feedback.ng-enter {
      animation: fade-in .3s; }
    .form__feedback.ng-leave {
      animation: fade-out .3s; }
    .form__feedback--succes {
      border-color: #4BB543;
      background-color: #fafdfa; }
    .form__feedback--error {
      border-color: #B33A3A;
      background-color: #fbf1f1; }
  .form.ng-submitted .form__field.ng-invalid,
  .form--validate .form__field.ng-invalid {
    border-color: #B33A3A; }
  .form__actions ~ .form__group {
    display: none; }

.button {
  color: #fff;
  cursor: pointer;
  display: table;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  border: 1px solid #95288B;
  text-decoration: none;
  background-color: #95288B;
  font-family: CorpidOfficeC1, sans-serif;
  font-size: 1em; }
  .button:hover {
    background-color: #fff;
    color: #95288B; }
  .button:after {
    content: ">";
    display: inline-block;
    transform: scaleX(0.5);
    margin-left: 0.5rem; }
  .button--text-only {
    color: #95288B;
    background-color: transparent;
    padding: 0;
    border: none; }
    .button--text-only:hover {
      color: #2e1c2a; }
  .button--inline {
    display: inline-table; }
  .button--outline {
    background-color: transparent;
    border-color: #fff; }
    .button--outline:hover {
      background-color: #fff;
      color: #95288B;
      border-color: #fff; }
  .button--dark {
    border-color: #fff;
    background-color: #2e1c2a; }
    .button--dark:hover {
      color: #2e1c2a; }

.overlay-box {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 123456789;
  background-color: rgba(9, 9, 9, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }
  .overlay-box.ng-enter {
    animation: fade-in .3s; }
  .overlay-box.ng-leave {
    animation: fade-out .3s; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .overlay-box__box {
    background-color: #fff;
    padding: 2rem;
    max-width: 60rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.45);
    position: relative; }
  .overlay-box__title {
    margin: 0; }
  .overlay-box__close {
    appearance: none;
    display: block;
    font-family: CorpidOfficeC1, sans-serif;
    background: none;
    border: none;
    font-size: 2em;
    position: absolute;
    top: -3rem;
    right: 0;
    color: #fff;
    cursor: pointer; }

.footer {
  margin-top: auto;
  background-color: #090909;
  color: #fff; }
  .footer__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 60rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    padding: 1rem 2rem; }
    @media (min-width: 640px) {
      .footer__wrapper {
        flex-direction: row; } }
  .footer__list {
    list-style: none;
    margin: 0;
    padding: 0; }
    .footer__list__item, .footer__list li {
      display: inline-block;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 100%; }
      @media (min-width: 640px) {
        .footer__list__item, .footer__list li {
          margin-right: 1rem;
          width: auto; } }
    @media (min-width: 640px) {
      .footer__list--social {
        margin-left: auto; } }
  .footer__list--social .footer__list__item {
    margin: 0.5rem 0.25em;
    width: auto; }
    @media (min-width: 640px) {
      .footer__list--social .footer__list__item {
        margin-right: 0;
        margin-left: 0.5em; } }
  .footer__link, .footer a {
    display: block;
    color: #fff;
    text-decoration: none; }
    .footer__link:hover, .footer a:hover {
      color: #95288B; }
    .footer__link--social, .footer a--social {
      padding: 0.5em;
      border-radius: 100%;
      background-color: #fff; }
      .footer__link--social:hover, .footer a--social:hover {
        background-color: #95288B; }
      .footer__link--social svg, .footer a--social svg {
        width: 1em;
        height: 1em;
        color: #090909;
        display: block; }
    .footer__link--contact svg, .footer a--contact svg {
      display: inline-block;
      margin-right: 0.25em;
      width: 1em;
      height: 1em;
      vertical-align: middle; }

.pagination {
  display: flex;
  margin: 0 auto;
  flex-basis: 100%;
  align-items: center;
  justify-content: center; }
  .pagination__list {
    list-style: none;
    margin: 2rem auto;
    padding: 0;
    display: flex; }
    .pagination__list__item {
      display: block; }
      .pagination__list__item--active {
        background-color: #95288B;
        color: #fff;
        border-radius: 2rem; }
      .pagination__list__item--active .pagination__list__item__link {
        color: #fff; }
        .pagination__list__item--active .pagination__list__item__link:hover {
          color: #fff; }
      .pagination__list__item__link {
        text-decoration: none;
        padding: 0.5rem;
        display: flex;
        height: 2rem;
        min-width: 2rem;
        text-align: center;
        align-items: center;
        justify-content: center; }
        .pagination__list__item__link--disabled {
          color: #d8d8d8; }

.area:nth-child(even),
.text:nth-child(even),
.metadata ~ .area:nth-child(odd),
.metadata ~ .text:nth-child(odd) {
  background-color: #f8f8f8;
  padding: 2rem 0;
  margin: 0; }

.metadata ~ .area:nth-child(even),
.metadata ~ .text:nth-child(even) {
  background-color: inherit;
  margin: 2rem 0;
  padding: 0; }

.mgnlEditor ~ main .area, .mgnlEditor ~ main .text {
  background-color: inherit;
  margin: 2rem 0;
  padding: 0; }
