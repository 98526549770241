@import "./common/colors";
@import "./common/dimensions";
@import "./common/media";
@import "./common/text";
@import "./common/animations";
@import "./mixins/image-replace";
@import "./mixins/fullwidth-wrapper";
@import "./mixins/color-overlay";
@import "./mixins/card-hover";

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font: {
    size: $font-size-base;
    family: $font-family-headings;
  }
  line-height: $line-height;

  @media (min-width: 1400px) {
    font-size: $font-size-base + 2px;
  }
}

body {
  background-color: #fff;
  margin: 0;
  color: $color-black;
  //min-height: 100vh;
  //display: flex;
  //flex-direction: column;
}

/** Angular specific styles **/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.link-as-wrapper {
  text-decoration: inherit;
  color: inherit;
}

a {
  color: $color-dark;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $color-primary;
  }
}

input, button {
  font-family: inherit;
  font-size: inherit;
}


// Global CMS bar fixes
.mgnlEditorBar,
.mgnlPlaceholder {
  flex-basis: 100%;
}

@import "./components/header";
@import "./components/sticky";
@import "./components/navigation";
@import "./components/language";
@import "./components/hero";
@import "./components/metadata";
@import "./components/areas";
@import "./components/entrances";
@import "./components/quote";
@import "./components/block";
@import "./components/people";
@import "./components/text";
@import "./components/images";
@import "./components/text-image";
@import "./components/video";
@import "./components/service-list";
@import "./components/downloads-list";
@import "./components/scroll-indicator";
@import "./components/background-parallax";
@import "./components/form";
@import "./components/button";
@import "./components/overlay-box";
@import "./components/footer";
@import "./components/pagination";

.area:nth-child(even),
.text:nth-child(even),
.metadata ~ .area:nth-child(odd),
.metadata ~ .text:nth-child(odd) {
  background-color: $color-gray-light;
  padding: $spacing-global 0;
  margin: 0;
}
.metadata ~ .area:nth-child(even),
.metadata ~ .text:nth-child(even) {
  background-color: inherit;
  margin: $spacing-global 0;
  padding: 0;
}

.mgnlEditor ~ main {
  .area, .text {
    background-color: inherit;
    margin: $spacing-global 0;
    padding: 0;
  }
}

