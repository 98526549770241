@mixin fullwidth-wrapper($center: n, $max-width: $max-width, $flex: true, $padded: true) {
    @if ($flex == true) {
      display: flex;
      @if ($center == x) {
        justify-content: center;
      }
      @if ($center == y) {
        align-items: center;
      }
      @if ($center == xy) {
        align-items: center;
        justify-content: center;
      }
    }
    max-width: $max-width;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    @if($padded == true) {
      padding: 0 $spacing-global;
    }
}
