.form {
  width: 100%;
  //overflow: hidden;
  &.ng-enter {
    animation: fade-in .3s;
  }
  &.ng-leave {
    animation: slide-up-fade-out .3s;
  }

  &--inline {
    width: auto;
    display: flex;
    align-items: flex-end;
    flex-flow: wrap;
    margin: 0  #{-$spacing-global/4};

    &.gatedDownload {
      flex-direction:column;
      align-items: flex-start;
    }
    .form {
      &__group {

        &.gatedDownload {
          width:80%;
        }
        display: inline-block;
        margin: 0 #{$spacing-global/4};
        flex-grow: 1;
        &--stay {
          flex-grow: 0;
        }
      }
      &__messages {
        margin: 0 #{$spacing-global/4};
      }
      &__button {
      }
    }
  }
  &__group {
    margin: $spacing-global/2 0;
    display: block;
  }
  &__messages {
    display: block;
    width: 100%;
  }
  &__label {
    cursor: pointer;
    font-weight: bold;
    display: block;
    margin: $spacing-global/4 0;
    position: relative;
    &--light {
      font-weight: normal;
    }
  }
  &__field {
    -webkit-appearance: none;
    padding: $spacing-global-block-v $spacing-global-block-h/2;
    border: 1px solid $color-gray-med;
    display: block;
    margin: $spacing-global/4 0;
    width: 100%;
    transition: all .2s;
    border-radius: 0.2rem;

    &--textarea {
      min-height: 14rem;
      resize: vertical;
    }
  }
  &__checkbox {
    position: absolute;
    visibility: hidden;
    &__dummy {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      border-radius: 0.2rem;
      border: 1px solid $color-gray-med;
      position: relative;
      vertical-align: -2px;
      margin-right: 0.5rem;
      transition: all .1s;

      &:before, &:after {
        content: "";
        display: block;
        transform: scaleX(1) rotate(36deg);
        position: absolute;
        transition: all .1s;
        opacity: 0;

      }

      &:before {
        height: 1.3em;
        width: 0.8em;
        top: -0.55em;
        left: 0.1em;
        border-right: 0.3em solid $color-primary;
        border-bottom: 0.3em solid $color-primary;
        z-index: 2;
      }
      &:after {
        border-right: 0.5em solid #fff;
        border-bottom: 0.5em solid #fff;
        top: -0.75em;
        left: 0.06em;
        width: 1em;
        height: 1.6em;
      }
    }
    &:checked + &__dummy {
      border-color: $color-primary;


      &:before, &:after {
        opacity: 1;
      }

      //&:after {
      //  font-family: $font-family-headings;
      //  color: $color-primary;
      //  content: "\2714";
      //  content: "\2713\0020";
      //  position: absolute;
      //  font-size: 1.5rem;
      //  top: -.75rem;
      //  left: 0;
      //  text-shadow: 1px 2px 0 #fff, -1px -1px 0 #fff;
      //}
    }
  }
  &__button {
    margin: $spacing-global/4 0;
    white-space: nowrap;
    display: block;
  }

  &__feedback {
    border: 1px solid $color-gray-med;
    padding: $spacing-global-block;
    background-color: $color-gray-light;
    margin: $spacing-global/2 0;
    transition: all .2s;
    border-radius: 0.2rem;

    &.ng-enter {
      animation: fade-in .3s;
    }
    &.ng-leave {
      animation: fade-out .3s;
    }

    &--succes {
      border-color: $color-success;
      background-color: lighten($color-success, 50%);
    }
    &--error {
      border-color: $color-warning;
      background-color: lighten($color-warning, 50%);
    }
  }

  &.ng-submitted &__field,
  &--validate &__field {
    &.ng-invalid {
      border-color: $color-warning;
    }
  }
  &__actions ~ &__group {
    display: none;
  }
}
