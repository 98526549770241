.language {
  order: -1;
  padding: $spacing-global/2 $spacing-global;
  @include on(large-and-up) {
    padding: 0;
    position: absolute;
    right: $spacing-global;
    top: -1px;
  }

  &__list {
    display: flex;
    list-style: none;
    margin: 0 -1px;
    padding: 0;
    @include on(large-and-up) {
      margin: 0;
    }

    &__item {
      display: block;
      flex: 1;
      width: 100%;
      @include on(large-and-up) {
        flex: none;
        width: auto;
        display: inline-table;
      }
      &__link {
        text-decoration: none;
        @include text-hide();
        display: block;
        border: {
          top: 1px solid $color-primary;
          right: 1px solid $color-primary;
          bottom: 1px solid $color-primary;
          left: 1px solid $color-primary;
        }
        color: $color-primary;

        @include card-hover();

        background-color: #fff;

        &--active {
          background-color: $color-primary;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
        padding: 0.2rem 0.5rem;
        &:before {
          color: inherit;
          content: attr(lang);
          display: block;
          font-family: $font-family-headings;
          font-size: 1rem;
          font-weight: bold;
          line-height: $line-height;
          text-transform: uppercase;
        }
      }
    }

  }
}
