.quote {
  //height: 505px;
  color: #fff;
  @include color-overlay();
  background: {
    size: cover;
    position: center;
  }
  &__wrapper {
    @include fullwidth-wrapper(xy);
    position: relative;
    z-index: 2;
    flex-flow: wrap;
    padding-top: ($spacing-global * 1);
    padding-bottom: ($spacing-global * 1);
    @include on(medium-and-up) {
      padding-top: ($spacing-global * 5);
      padding-bottom: ($spacing-global * 2);
    }
  }
  &__block {
      @include on(medium-and-up) {
        width: 85%;
      }
      margin: 0 auto;
  }
  &__link {
    float: right;
  }
  &__text {
    font-size: 2rem;
    @include on(medium-and-up) {
      font-size: 3rem;
    }
    text-shadow: $text-shadow;
    margin: 0;
    &--with-quotation {
      &:before {
        content: "\201C";
      }
      &:after {
        content: "\201D";
      }
    }
  }
  &__attribution {
    &:before {
      content: "\2014";
    }
    //&:after {
    //  content: "\2014";
    //}
  }
  &__footer {
    margin-top: ($spacing-global);
    @include on(medium-and-up) {
      margin-top: ($spacing-global * 2);
    }
    flex-basis: 100%;
  }
}
