.downloads-list {
  list-style: none;
  margin: 0;
  padding: 0;
  &__item {
    border-bottom: 1px solid $color-gray-med;
    margin: 0 0 $spacing-global/4 0;
    &__link {
      display: block;
      text-decoration: none;
      width: 100%;
      padding: $spacing-global/4 0;
      svg {
        margin: 0 $spacing-global/4;
        display: inline-block;
        vertical-align: text-bottom;
      }
    }
  }
}
