.background-parallax {
  position: relative;
  overflow: hidden;
  &__image {
    background: {
      size: cover;
      position: center;
    }
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
