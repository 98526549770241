.sticky {
  left: 0;
  right: 0;
  top: 0;
  z-index: 12345678;
  transition: all .4s ease-in-out;
  transform: translateY(0%);
  opacity: 1;
  position: absolute;
  &--enabled {
    position: fixed;
  }
  &--enabled.sticky--hidden {
    transform: translateY(-100%);
    opacity: 0;
  }

  &__placeholder {
    //display: none;
  }
  &--enabled + &__placeholder {
    width: 100%;
    display: block;
  }
}
