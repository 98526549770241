.text-image {
  margin: $spacing-global 0;

  &__wrapper {
    @include fullwidth-wrapper($flex: false);
  }

  &__container {
    display: flex;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    flex-flow: wrap;
    @include on(medium-and-up) {
      max-width: 85%;
    }
    margin: 0 auto;
  }

  &__text {
    flex: 2;
    @include on(small) {
      flex-basis: 100%;
    }
    & h2 {
      margin-top: 0;
    }
    & > p:first-child {
      margin-top: 0;
    }
  }

  &__title {
    flex-basis: 100%;
    margin-top: 0;
    @include on(small) {
      order: -3;
    }
  }

  &__link {
    flex-basis: 100%;
  }

  &__figure {
    width: 100%;
    margin: 0;
    flex: 2;
    //outline: 1rem solid black;

    @include on(small) {
      flex-basis: 100%;
      margin: #{$spacing-global/2} #{-$spacing-global};
      order: -1;
      //outline: 1rem solid blue;
    }
    @include on(medium-and-up) {
      //outline: 1rem solid green;
    }
    @include on(large-and-up) {
      flex: 1;
      //outline: 1rem solid red;
    }
    @include on(medium-and-up) {
      &--right {
        margin-left: $spacing-global;
      }
      &--left {
        margin-right: $spacing-global;
        margin-right: $spacing-global;
      }
    }
  }

  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    //background-color: $color-gray-light;
    &--padded {
      padding: $spacing-global;
    }
  }
}
