.block {
  position: relative;

  flex: 1;
  width: 100%;

  margin: $spacing-global 0;
  @include on(medium-and-up) {
    margin: $spacing-global/2;
    @include flex-width(calc(100% / 3 - #{$spacing-global}));
  }


  &--larger {
    @include on(medium-and-up) {
      @include flex-width(calc(100% / 2 - #{$spacing-global}));
    }
  }

  &__wrapper {
  }
  &__header {
    &--hover {
      @include card-hover();
    }
  }
  &__image {
    max-width: 100%;
    width: 100%;
    //height: auto;
    //height: 0px;
    //padding-bottom: 50%;
    //background-size: cover;
    //background-position: center;
    margin-bottom: $spacing-global/4;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__author {
    color: $color-primary;
    display: block;
    margin: 0.5rem 0;
  }
  &__date {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(white, 0.85);
    padding: $spacing-global-block;
    color: $color-primary;
  }
  &__title {
    margin: 0;
  }
  &__text {
    margin: 0;
    color: $color-gray;
  }
}
