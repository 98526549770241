@mixin text-hide() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
@mixin image-replace($imageURL) {
  background: url($imageURL) center no-repeat;
  @include text-hide();
}
