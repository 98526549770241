.person {
  background-color: #fff;
  display: flex;
  //height: 16rem;
  position: relative;
  margin: $spacing-global/2 0;
  @include on(medium-and-up) {
    //width: 12.5rem;
    margin: $spacing-global/2;
    //flex: 0 1 12.5rem;
    @include flex-width(calc(100% / 4 - #{$spacing-global}));
  }
  //width: 100%;
  @include card-hover();

  &__wrapper,
  &__link {
    display: block;
    text-decoration: none;
    width: 100%;
  }
  &__wrapper {
    @include on(small) {
      display: flex;
    }
  }
  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }

  &__detail {
    background-color: rgba(#fff, 1);
    color: $color-black;
    @include on(medium-and-up) {
      margin-top: auto;
    }
    padding: $spacing-global-block-v;
    text-align: center;
    display: block;
    width: 100%;
    &__name {
      margin: 0;
    }
    &__role {
      display: block;
      font-weight:bold;
    }
    &__mail {
      display: block;
      margin-top: $spacing-global-block-v;
      margin-bottom: $spacing-global-block-v/2;
      font-size: 0.65rem;
      text-decoration: none;
      color: inherit;
      svg {
        display: inline-block;
        margin-right: 0.25em;
        width: 1em;
        height: 1em;
        vertical-align: middle;
      }
    }
  }
  &--alone {
    flex: none;
    @include on(small) {
      @include card-hover($disabled: true);
      background-color: transparent;
    }
  }
  &--larger {
    flex: 1;
    width: auto;
    max-width: calc(100%/3 - #{$spacing-global});
  }
  &--alone &__image {
    border-radius: 100%;
    margin: $spacing-global/2 $spacing-global;
    @include on(medium-and-up) {
      margin: $spacing-global/2 $spacing-global 0 $spacing-global;
    }
    max-width: calc(100% - #{$spacing-global*2});
    max-height: calc(100% - #{$spacing-global/2});
    width: auto;
  }
  &--alone &__detail__mail {
    //color: $color-primary;
  }
  &--alone &__wrapper > &__link {
    @include on(small) {
      display: none;
    }
  }
  &--alone &__detail {
    @include on(small) {
      text-align: left;
      background-color: transparent;
      padding: 0;
      & > a {
        color: #fff;
      }
    }
  }

  &-with-text {
    flex: 1;
    .person {
      max-width: none;
    }
    @include on(medium-and-up) {
      max-width: calc(100% / 3);
      .person__text {
        margin: $spacing-global/2;
      }
    }
  }
}
