.metadata {
    background-color: $color-gray-light;
    &__wrapper {
        @include fullwidth-wrapper();
    }
    &__date {
        display: block;
        padding: $spacing-global-block;
        font-size: 0.8rem;
        margin-left: auto;
        margin-right: -1.25rem;
        &__published {
            color: $color-primary;
        }
        &__modified {
            color: $color-primary;
        }
    }
}


