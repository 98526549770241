.navigation {
  width: 100%;
  flex-basis: 100%;

  max-height: 0px;
  overflow: hidden;
  transition: all .3s;
  display: flex;
  flex-direction: column;
  &--open {
    max-height: 40rem;
  }

  @include on(large-and-up) {
    display: block;
    max-height: none;
    margin-left: $spacing-global/2;
    flex-direction: row;
  }

  &__list {
    @include on(large-and-up) {
      display: flex;
    }
    list-style: none;
    padding: 0;
    margin: 0;
    &__item {
      //display: inline-block;
      position: relative;
      @include on(large-and-up) {
        margin-left: $spacing-global/2;
        position: static;
      }
      &--search {
        margin-left: auto;
      }
      &__link {
        text-decoration: none;
        color: $color-black;
        padding: $spacing-global/2 0;
        display: block;
        position: relative;
        font-weight: bold;
        &:hover {
          color: $color-primary;
        }
        &--active {
          color: $color-primary;
          //border-bottom: 1px solid black;
        }
        padding: $spacing-global/2 $spacing-global;
        border-bottom: 1px solid $color-gray-med;

        @include on(large-and-up) {
          padding: $spacing-global/2 0;
          border-bottom: none;

        }

        &__toggle {
          display: flex;
          margin-left: auto;
          padding: 0rem 1.5rem;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          justify-content: center;
          align-items: center;
          @include on(large-and-up) {
            display: none;
          }

          &:after {
            content: ">";
            display: inline-block;
            transition: all .2s;
            transform: rotate(90deg) scaleX(0.5);
          }
        }
        &--search {
          display: flex;
          align-items: center;
          svg {
            order: 9999;
            margin-left: auto;
            height: 1.5em;
            display: inline-block;
          }
          @include on(large-and-up) {
            span {
              display: none;
            }
            svg {
              order: initial;
            }
          }
        }
      }
    }
  }

  &__list--depth-2 {
    max-height: 0px;
    overflow: hidden;
    transition: all .2s;
    @include on(large-and-up) {
      display: none;
      position: absolute;
      min-width: 240px;
      max-width: 320px;
      max-height: none;
    }
    z-index: 99;
    background: rgba($color-gray-light, 1);
    @include on(large-and-up) {
      background: rgba(#fff, 1);
      margin: 0 -#{$spacing-global/2};
    }
    flex-direction: column;
  }
  &__list__item:hover &__list--depth-2 {
    display: flex;
  }

  &__list__item--open &__list--depth-2 {
    max-height: 20rem;
  }
  &__list__item--open &__list__item__link__toggle:after {
    transform: rotate(-90deg) scaleX(0.5);
  }
  &__list--depth-2 .navigation__list {
    &__item {
      margin: 0;
      &__link {
        @include on(large-and-up) {
          background-color: #fff;
          padding: $spacing-global/2;
        }
        border-bottom: 1px solid $color-gray-med;
      }
    }
  }
}


$naviconsize: 1.5rem;
$naviconline: 3px;
.nav-icon {
  display: flex;
  cursor: pointer;
  overflow: visible;
  width: $naviconsize;
  height: $naviconsize;
  flex-flow: wrap;
  margin: auto $spacing-global auto auto;
  @include on(large-and-up) {
    display: none;
  }
  &__bar {
    transition: all .2s;
    height: $naviconline;
    width: $naviconsize;
    background-color: currentColor;
    margin: auto;
    flex-basis: 100%;
    transform-origin: 50% 50%;
    border-radius: $naviconline;
  }
}

.nav-icon--open {
  .nav-icon__bar:first-child {
    transform: rotate(45deg) translate(#{$naviconsize/4},#{$naviconsize/4});
  }
  .nav-icon__bar:nth-child(2) {
    transform: scaleX(1.25);
    opacity: 0;
  }
  .nav-icon__bar:last-child {
    transform: rotate(-45deg) translate(#{$naviconsize/4},-#{$naviconsize/4}) translateX(-1px);
  }
}
