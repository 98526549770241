.overlay-box {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 123456789;
  background-color: rgba($color-black, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  &.ng-enter {
    animation: fade-in .3s;
  }
  &.ng-leave {
    animation: fade-out .3s;
  }


  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &__box {
    background-color: #fff;
    padding: $spacing-global;
    max-width: $max-width;
    box-shadow: $box-shadow;
    position: relative;
  }
  &__title {
    margin: 0;
  }
  &__close {
    appearance: none;
    display: block;
    font-family: $font-family-headings;
    background: none;
    border: none;
    font-size: 2em;
    position: absolute;
    top: -$spacing-global*1.5;
    right: 0;
    color: #fff;
    cursor: pointer;

  }

}
